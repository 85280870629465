const base = '/api'
const api = {
    Login: base + '/flyer_student/login',
    Img: base + '/flyer_student/captcha_image',
    QueryStudentScores: base + '/flyer_student/query_student_scores',
    getCategories: base + '/flyer_student/get_categories',
    queryApplyRecords: base + '/flyer_student/query_apply_records',
    createApplyRecord: base + '/flyer_student/create_apply_record',
    ResetPassword: base + '/flyer_student/reset_password',
}
export default api
