<template>
  <a-card title="通知" >
    <p>{{ content }}</p>
    <div slot="extra" style="font-size: 12px; text-align: right;">{{ time }}</div>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      content: "欢迎使用北航飞行学院养成分平台",
      time: this.getCurrentTime()
    };
  },
  methods: {
    getCurrentTime() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}年${month}月${day}日`;
    }
  }
};
</script>

<style>
/* 样式 */
</style>
