<template>
  <div class="layout">
    <nav class="navbar navbar-expand-lg navbar-dark bg-secondary">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">飞行学院</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: current === '/score_record' }"
                @click="navigateTo('/score_record')"
                >扣分记录</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: current === '/apply_record' }"
                @click="navigateTo('/apply_record')"
                >加分申请</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: current === '/password_change' }"
                @click="navigateTo('/password_change')"
                >密码修改</a
              >
            </li>
          </ul>
          <ul class="navbar-nav ms-auto">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="bi bi-person-circle"></i> {{ userName }}
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" @click="logout">
                    <i class="bi bi-box-arrow-right"></i> 退出
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="main-content mt-3">
      <div class="container">
        <router-view />
      </div>
    </div>

    <footer class="text-center py-3 bg-light">
      © Copyright 飞行学员准军事化管理系统 All Rights Reserved. 
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: '',
      current: '', // 用于标识当前激活的导航项
      username: localStorage.user_id,
      showAdmin: false,
      role_type: localStorage.role_type,
    };
  },
  mounted() {
    this.loadUserName();
   
    //console.log(this.userName);
    if (!localStorage.token) {
      this.$message.warning("请先登录！");
      this.$router.push('/login');
      
    }
  },
  methods: {
    loadUserName() {
      // 从 localStorage 获取用户名
      const name = localStorage.getItem("name");
      this.userName = name ? name : "Guest";
    },
    navigateTo(route) {
      this.current = route; // 更新当前激活的导航项
      this.$router.push(route);
    },
    logout() {
      localStorage.clear();
      this.$router.push({ path: '/login' });
    },
  },
};
</script>

<style scoped>
/* 自定义样式 */
.navbar-nav .nav-link {
  color: white;
}

.navbar-nav .nav-link.active {
  color: #ffd04b;
}

.main-content {
  min-height: 600px;
}

footer {
  margin-top: 20px;
  background-color: #f8f9fa;
}
</style>
