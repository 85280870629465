<template>
  <div class="bg-image">
    <div class="main">
      <el-row :gutter="25" class="d-flex justify-content-center gx-5 px-4">
        <el-col :span="24" :md="8" :lg="6" class="p-0">
          <el-card class="card">
            <div class="title">飞行学院学生养成分系统</div>
            <div class="label">登录</div>
            <el-form :model="form" ref="form" class="user-layout-login">
              <el-alert
                  v-if="isLoginError"
                  type="error"
                  show-icon
                  style="margin-bottom: 24px;"
                  title="用户名或密码错误"
              />
              <el-form-item prop="student_number" :rules="[{ required: true, message: '请输入学号', trigger: 'blur' }]">
                <el-input
                    v-model="form.student_number"
                    size="large"
                    placeholder="学号"
                    prefix-icon="el-icon-user"
                />
              </el-form-item>
              <el-form-item prop="password" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]">
                <el-input
                    v-model="form.password"
                    size="large"
                    type="password"
                    placeholder="密码"
                    prefix-icon="el-icon-lock"
                />
              </el-form-item>
              <el-form-item prop="captcha" :rules="[{ required: true, message: '请输入验证码', trigger: 'blur' }]">
                <el-row :gutter="10" type="flex" align="middle">
                  <el-col :span="16">
                    <el-input
                        v-model="form.captcha"
                        size="large"
                        placeholder="验证码"
                        prefix-icon="el-icon-setting"
                    />
                  </el-col>
                  <el-col :span="8">
                    <img
                        :src="captchaImage"
                        alt="验证码"
                        @click="refreshCaptcha"
                        style="cursor: pointer; width: 100%;"
                    />
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-button
                    type="primary"
                    size="large"
                    :loading="loginBtn"
                    @click="doLogin"
                    style="width: 100%"
                >登录
                </el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import api from "../../api/index"
import axiosInstance from "../../api/axiosInstance";

export default {
  data() {
    return {
      form: {
        student_number: "",
        password: "",
        captcha: ""
      },
      captchaImage: '',
      isLoginError: false,
      loginBtn: false,
      tokenExpired: false
    };
  },
  mounted() {
    if (localStorage.getItem('tokenExpired') === 'true') {
      this.tokenExpired = true;
      this.$message.warning("登录状态失效，请重新登录！");
      localStorage.removeItem('tokenExpired');  // 移除标志
    }
    this.refreshCaptcha();
  },
  methods: {
    refreshCaptcha() {
      // 请求后端获取验证码图片
      fetch(api.Img)
          .then(response => response.blob())
          .then(imageBlob => {
            // 将图片 Blob 对象转换为 Data URL
            const reader = new FileReader();
            reader.onload = () => {
              // 将 Data URL 赋值给 captchaImage，触发页面更新
              this.captchaImage = reader.result;
            };
            reader.readAsDataURL(imageBlob);
          })
          .catch(error => {
            console.error('Error fetching captcha:', error);
          });
    },
    doLogin() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loginBtn = true;
          axiosInstance.post(api.Login, {
            student_number: this.form.student_number,
            password: this.form.password,
            captcha: this.form.captcha,
          })
              .then(response => {
                let status = response.data.status;
                if (status === 'success') {
                  localStorage.token = response.data.data.token;
                  localStorage.password_modified = response.data.data.password_modified;
                  localStorage.name = response.data.data.name;
                  if (response.data.data.password_modified == 0) {
                    this.$router.push({path: "/password_change"})
                    setTimeout(() => {
                      this.$notification.success({
                        message: "欢迎",
                        description: `请及时修改密码, ${response.data.data.name}同学`
                      });
                    }, 1000);
                  } else {
                    this.$router.push({path: "/board"});
                    setTimeout(() => {
                      this.$notification.success({
                        message: "欢迎",
                        description: `欢迎回来, ${response.data.data.name}同学`
                      });
                    }, 1000);
                  }
                  this.isLoginError = false;
                } else if (status === 'error') {
                  this.$message.warning(response.data.message);
                  this.refreshCaptcha();
                  this.isLoginError = true;
                }
              })
              .catch(error => {
                this.isLoginError = true;
                this.$notification["error"]({
                  message: "错误",
                  description: ((error.response || {}).data || {}).message || "请求出现错误，请稍后再试",
                  duration: 4
                });
              })
              .finally(() => {
                this.loginBtn = false;
              });
        } else {
          this.$message.warning("请输入所有信息后登录");
        }
      });
    }
  }
}
</script>

<style scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}

.main {
  margin: 200px 0;
}

.title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  margin-bottom: 24px;
}

.label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  margin-bottom: 16px;
}

.bg-image {
  background-image: url('../../assets/pexels-pixabay-258149.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>