import Vue from 'vue'
import Router from 'vue-router'

import LoginPage from './components/page/LoginPage.vue'
import UserLayout from './components/page/UserLayout.vue'
import Board from "./components/board/Board.vue";
import ScoreRecord from "./components/page/ScoreRecord.vue";
import ApplyRecord from "./components/page/ApplyRecord.vue";
import ResetPassword from "./components/page/ResetPassword.vue";

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/student/',
    routes: [
        {
            path: '/',
            name: 'index',
            redirect: '/login',
            component: UserLayout,
            children: [
                {
                    path: '/board',
                    name: 'board',
                    component: Board,
                },
                {
                    path: '/score_record',
                    name: 'score_record',
                    component: ScoreRecord,
                },
                {
                    path: '/apply_record',
                    name: 'apply_record',
                    component: ApplyRecord,
                },
                {
                    path: '/password_change',
                    name: 'password_change',
                    component: ResetPassword,
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: LoginPage
        }
    ]
})