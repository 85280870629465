import Vue from "vue";
import router from "./router";
import Antd from "ant-design-vue";
import App from "./App.vue";
//import "lib-flexible";

import "ant-design-vue/dist/antd.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

Vue.config.productionTip = false;

Vue.use(Antd);
Vue.use(ElementUI);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
