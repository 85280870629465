import axiosInstance from './axiosInstance';  // 确保路径正确
import api from "./index";

export async function fetchClasses() {
    try {
        const response = await axiosInstance.get(api.getClassInfo);
        const resData = response.data;
        if (resData.status === 'success') {
            return resData.data;
        } else {
            throw new Error(resData.message);
        }
    } catch (error) {
        throw new Error('获取班级信息失败，请稍后再试');
    }
}

function formatCategories(categories, isAdd) {
    return categories.reduce((acc, category) => {
        if ((isAdd && category.recommended_score > 0) || (!isAdd && category.recommended_score <= 0)) {
            const formattedCategory = {
                value: category.category_id,
                label: category.category_name,
                children: category.sub_categories.map(sub => ({
                    value: sub.sub_category_id,
                    label: sub.sub_category_name,
                }))
            };
            acc.push(formattedCategory);
        }
        return acc;
    }, []);
}

export async function fetchCategories(isAdd) {
    try {
        const response = await axiosInstance.get(api.getCategories);
        const resData = response.data;
        if (resData.status === 'success') {
            let categories = resData.data;
            return formatCategories(categories, isAdd);
        } else {
            throw new Error(resData.message);
        }
    } catch (error) {
        throw new Error('获取分类信息失败，请稍后再试');
    }
}

export async function fetchGrades() {
    try {
        const response = await axiosInstance.get(api.getGradeInfo);
        const resData = response.data;
        if (resData.status === 'success') {
            return resData.data;
        } else {
            throw new Error(resData.message);
        }
    } catch (error) {
        throw new Error('获取年级信息失败，请稍后再试');
    }
}

export async function fetchClassIdsByGrades(gradeIds) {
    try {
        const response = await axiosInstance.post(api.getClassIdsByGrades, {grade_ids: gradeIds});
        const resData = response.data;
        if (resData.status === 'success') {
            return resData.data;
        } else {
            throw new Error(resData.message);
        }
    } catch (error) {
        throw new Error('获取班级列表失败，请稍后再试');
    }
}