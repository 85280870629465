<template>
  <div class="p-1">
    <!-- 查询选项 -->
    <div class="row mb-2">
      <div class="col-md-12">
        <el-card class="card">
          <h3 class="card-title">申请记录查询</h3>
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="申请类型">
              <template v-if="isWideScreen">
              <el-cascader
                  ref="search_cascader"
                  class="f-width" 
                  :options="categories_cascader"
                  :props="{ checkStrictly: true }"
                  placeholder="选择类型"
                  @change="handleTypeChange"
                  clearable></el-cascader>
              </template>
              <template v-else>
                <el-cascader-panel
                  ref="search_cascader"
                  class="f-width" 
                  :options="categories_cascader"
                  :props="{ checkStrictly: true }"
                  placeholder="选择类型"
                  @change="handleTypeChange"
                  clearable></el-cascader-panel>
                  <el-button @click="handleReset" class="f-width">清除</el-button>
              </template>

            </el-form-item>
            <el-form-item label="申请时间">
              <el-date-picker v-model="searchForm.startTime" type="date" value-format="yyyy-MM-dd"
              class="h-width" placeholder="开始日期"></el-date-picker>
              -
              <el-date-picker class="h-width" v-model="searchForm.endTime" type="date" value-format="yyyy-MM-dd"
                              placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="申请状态">
              <el-select v-model="searchForm.status" placeholder="选择申请状态"  class="f-width">
                <el-option label="申请中" value="0"></el-option>
                <el-option label="通过" value="1"></el-option>
                <el-option label="拒绝" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button type="primary" @click="openApplyDialog">申请加分</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </div>

    <!-- 查询结果 -->
    <div class="row mt-2">
      <div class="col-md-12">
        <el-card>
          <el-table :data="tableData" style="width: 100%" @sort-change="handleSortChange">
            <el-table-column prop="apply_category" label="申请类别" witdh="120px" sortable="custom"></el-table-column>
            <el-table-column prop="apply_subcategory" label="申请子类别" witdh="120px"
                             sortable="custom"></el-table-column>
            <el-table-column prop="score" label="申请加分数" witdh="50px"
                             sortable="custom"></el-table-column>
            <el-table-column prop="apply_remarks" label="申请备注" width="100px"></el-table-column>
            <el-table-column prop="create_time" label="申请时间" sortable="custom"></el-table-column>
            <el-table-column prop="update_time" label="审批时间" sortable="custom"
                             :formatter="formatUpdateTime"></el-table-column>
            <el-table-column prop="status" label="状态" width="100" :formatter="formatStatus">
              <template slot-scope="scope">
                <el-tag :type="getStatusType(scope.row.status)">
                  {{ formatStatus(scope.row.status) }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              @current-change="handlePageChange"
          />
        </el-card>
      </div>
    </div>
    <!-- 申请对话框 -->
    <el-dialog title="申请加分" :visible.sync="applyDialogVisible" width="80%">
      <el-form :model="applyForm">
        <el-form-item label="申请类型">
          <template v-if="isWideScreen">
          <el-cascader
              class="f-width-a"
              ref="apply_cascader"
              
              v-model="applyCategoryList"
              :options="categories_cascader"
              :props="{ checkStrictly: true }"
              placeholder="选择类型"
              @change="handleApplyTypeChange"
              clearable></el-cascader>
            </template>
            <template v-else>
              
              <el-cascader-panel
                  ref="apply_cascader"
                  class="f-width-a"
                  v-model="applyCategoryList"
                  :options="categories_cascader"
                  :props="{ checkStrictly: true }"
                  placeholder="选择类型"
                  @change="handleApplyTypeChange"
                  clearable></el-cascader-panel>
              
            
            </template>
        </el-form-item>
        <el-form-item label="申请备注">
          <el-input v-model="applyForm.applyRemarks" type="textarea" class="f-width-a" rows="5" placeholder="输入申请备注"></el-input>
        </el-form-item>
        <el-form-item label="获奖时间">
          <el-date-picker class="f-width-a" v-model="applyForm.applyRecordTime" type="date"
                          value-format="yyyy-MM-dd"
                          :picker-options="{
      disabledDate(time) {
        return time.getTime() > Date.now();
      }
    }"
                          placeholder="选择申请日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="申请分数">
          <el-input v-model="applyForm.score" type="number" placeholder="输入申请分数"  class="f-width-a"></el-input>
        </el-form-item>
        <el-form-item label="申请凭证">
          <el-upload 
              action=""
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-change="handleFileChange"
              accept=".pdf, .png, .jpg, .jpeg, .gif, .bmp, .tiff, .svg"
              :limit="1"
              :auto-upload="false"
              :file-list="fileList"
              style="width: 100%;"
          >
            <el-button type="primary" class="f-width-a">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传pdf和图片</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="applyDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitApply">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";  // 假设你有这个实例配置
import api from "../../api/index";
import {fetchCategories} from "../../api/api_util";

export default {
  data() {
    return {
      isWideScreen: window.innerWidth >= 768, // 可以根据需要调整宽度阈值
      searchForm: {
        applyCategory: '',
        applySubcategory: '',
        startTime: '',
        endTime: '',
        status: '',
        applyRemarks: ''
      },
      applyForm: {
        applyCategory: '',
        applySubcategory: '',
        applyRemarks: '',
        applyRecordTime: '',
        score: '',
        applyFilePath: ''
      },
      categories: [],  // 获取类别数据
      subcategories: [],  // 获取子类别数据
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      sortField: '',
      sortOrder: '',
      categories_cascader: [],
      applyDialogVisible: false,
      fileList: [],
      fileContent: null, // 暂存文件内容
      applyCategoryList: [],
    };
  },
  computed: {
  
  },
  methods: {
    handleReset() {
      this.$refs.search_cascader.checkedValue = "";
    },
    handleReset_apply() {
      this.$refs.apply_cascader.checkedValue = "";
    },
    handleTypeChange(val) {
      console.log(val);
      const checkedNodes = this.$refs['search_cascader'].getCheckedNodes();
      if (checkedNodes.length === 0) {
        this.searchForm.applyCategory = '';
        this.searchForm.applySubcategory = '';
        return;
      }
      if (checkedNodes[0].pathLabels.length > 0) {
        this.searchForm.applyCategory = checkedNodes[0].pathLabels[0];
        if (checkedNodes[0].pathLabels.length > 1) {
          this.searchForm.applySubcategory = checkedNodes[0].pathLabels[1];
        }
      }
      console.log(this.searchForm);
    },
    handleApplyTypeChange(val) {
      console.log(val);
      const checkedNodes = this.$refs['apply_cascader'].getCheckedNodes();
      if (checkedNodes.length === 0) {
        this.applyForm.category = '';
        this.applyForm.sub_category = '';
        return;
      }
      if (checkedNodes[0].pathLabels.length > 0) {
        this.applyForm.applyCategory = checkedNodes[0].pathLabels[0];
        if (checkedNodes[0].pathLabels.length > 1) {
          this.applyForm.applySubcategory = checkedNodes[0].pathLabels[1];
        }
      }
      console.log(this.searchForm);
    },
    handleSearch() {
      const params = {
        apply_category: this.searchForm.applyCategory,
        apply_subcategory: this.searchForm.applySubcategory,
        start_time: this.searchForm.startTime,
        end_time: this.searchForm.endTime,
        status: this.searchForm.status,
        apply_remarks: this.searchForm.applyRemarks,
        page: this.currentPage,
        page_size: this.pageSize,
        sort_field: this.sortField,
        sort_order: this.sortOrder
      };

      axiosInstance.get(api.queryApplyRecords, {params})
          .then(response => {
            if (response.data.status === 'success') {
              this.tableData = response.data.data.apply_records;
              this.total = response.data.data.total;
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch(error => {
            this.$message.error('获取数据失败：' + error);
          });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.handleSearch();
    },
    handleSortChange({prop, order}) {
      this.sortField = prop;
      this.sortOrder = order === 'ascending' ? 'asc' : 'desc';
      this.handleSearch();
    },
    openApplyDialog() {
      this.applyDialogVisible = true;
    },
    handlePreview(file) {
      console.log("handleFileChange", file)
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.fileList = []
        this.$message.error('上传文件大小不能超过 5MB!');
        return false;
      }
      // 检查文件类型，只接受 PDF 和图片类型
      const isPdfOrImage = file.raw.type === 'application/pdf' || file.raw.type.startsWith('image/');
      if (!isPdfOrImage) {
        this.fileList = [];
        this.$message.error('只接受 PDF 和图片类型的文件!');
        return false;
      }
    },
    handleRemove(file, fileList) {
      this.fileList = [];
      this.fileContent = null; // 移除文件时清空暂存的文件内容
      console.log(file, fileList);
    },
    handleFileChange(file, fileList) {
      console.log("handleFileChange", file)
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.fileList = []
        this.$message.error('上传文件大小不能超过 5MB!');
        return false;
      }
      // 更新 fileList 和 applyForm 中的文件名称
      this.fileList = fileList;
      this.fileContent = file.raw;
      const isPdfOrImage = this.fileContent.type === 'application/pdf' || this.fileContent.type.startsWith('image/');
      if (!isPdfOrImage) {
        this.fileList = [];
        this.$message.error('只接受 PDF 和图片类型的文件!');
        return false;
      }
    },
    submitApply() {
      if (this.applyForm.applyCategory.length == 0) {
        this.$message.warning("请选择申请类型");
        return;
      }
      if (this.applyForm.applyRemarks.length == 0) {
        this.$message.warning("请输入申请原因");
        return;
      }
      if (this.applyForm.applyRecordTime.length == 0) {
        this.$message.warning("请选择获奖时间");
        return;
      }
      if (!this.fileContent) {
        this.$message.warning("请选择PDF文件");
        return;
      }
      if (this.applyForm.score <= 0) {
        this.$message.warning("加分必须是正数");
        return;
      }
      const formData = new FormData();
      formData.append('apply_category', this.applyForm.applyCategory);
      formData.append('apply_subcategory', this.applyForm.applySubcategory);
      formData.append('apply_remarks', this.applyForm.applyRemarks);
      formData.append('apply_record_time', this.applyForm.applyRecordTime);
      formData.append('score', this.applyForm.score);
      formData.append('apply_file', this.fileContent); // 添加暂存的文件内容

      axiosInstance.post(api.createApplyRecord, formData)
          .then(response => {
            if (response.data.status === 'success') {
              this.$message.success('申请提交成功');
              this.applyDialogVisible = false;
              this.applyForm.applyCategory = '';
              this.applyForm.applySubcategory = '';
              this.applyForm.applyRemarks = '';
              this.applyForm.applyRecordTime = '';
              this.applyForm.score = 0;
              this.fileContent = null;
              this.fileList = [];
              this.applyCategoryList = [];
              this.handleSearch();
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch(error => {
            this.$message.error('申请提交失败：' + error);
          });
    },
    formatUpdateTime(row) {
      if (row.status != 0) {
        return row.update_time
      } else {
        return ''
      }
    },
    formatStatus(status) {
      const statusMap = {
        0: '审核中',
        1: '通过',
        2: '拒绝'
      };
      return statusMap[status];
    },
    getStatusType(status) {
      const typeMap = {
        0: 'info',
        1: 'success',
        2: 'danger'
      };
      return typeMap[status];
    }
  },
  async mounted() {
    this.categories_cascader = await fetchCategories(true);
    this.categories_cascader.push({
      value: 14,
      label: '其他',
      child: [],
    });
    this.handleSearch();
  }
};
</script>

<style scoped>


.el-form-item {
  margin-right: 20px;
}


.el-table .el-table__header {
  background-color: #f5f7fa;
}

.f-width {
  width: 220px;
}

.f-width-a {
  width: 100%!important;
}

.h-width {
  width: 105px!important;
}


.el-cascader-panel {
  width: 220px;
  overflow-x: auto; /* 当内容超出宽度时显示横向滚动条 */
}
</style>